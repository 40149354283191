import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <section id="contact">
      <div className="inner">
        <h2 className="major">WHO IS DAVID URIEL?</h2>

        <div className="row">
          <div className="col-8 col-12-medium">
            <p>You can download my resume, or contact me to find out more.</p>
          </div>
          <div className="col-4 col-12-medium">
            <ul className="actions stacked">
              <li>
                <a
                  href="/resume/David-Uriel-Guadarrama-Resume.pdf"
                  className="button fit primary icon solid fa-download"
                >
                  My Resume
                </a>
              </li>
              <li>
                <a href="mailto:david@daviduriel.com" className="button fit">
                  Contact Me
                </a>
              </li>
            </ul>
          </div>
        </div>

        <ul className="icons">
          {config.socialLinks.map(social => {
            const { style, icon, name, url } = social;
            return (
              <li key={url}>
                <a href={url} className={`icon ${style} ${icon}`}>
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          })}
        </ul>

        {/* <ul className="contact">
          <li className="fa-home">{config.address}</li>

          {config.socialLinks.map(social => {
            const { icon, url } = social;
            return (
              <li className={`${icon}`} key={url}>
                <a href={url}>{url}</a>
              </li>
            );
          })}
        </ul> */}
        <ul className="copyright">
          <li>
            &copy; <a href="https://links.daviduriel.com">David Uriel</a>.
          </li>
          <li>All rights reserved.</li>
        </ul>
      </div>
    </section>
  );
}
