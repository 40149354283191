module.exports = {
  siteTitle: 'David Uriel - Personal Portfolio', // <title>
  manifestName: 'David Uriel',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'David Uriel Guadarrama',
  subHeading: 'A Frontend Web Developer.',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/daviduriel/',
    },
    {
      style: 'brands',
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/DaveUriel',
    },
    {
      style: 'brands',
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/dave.uriel/',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:david@daviduriel.com',
    },
  ],

  address: 'Orange County, CA',
};
